import Api from "./axios"

const siteService = {

    getSites(region_ids = null, project_ids = null) {
        return Api.get("/sites/", {
            params: {
                region_ids: JSON.stringify(region_ids),
                project_ids: JSON.stringify(project_ids),
            }
        })
    },
    getSite(site_id) {
        return Api.get(`/sites/${site_id}/`)
    }
}

export default siteService;