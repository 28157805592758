<template>
  <v-container>
    <v-row align-content="center" justify="center">
      <v-col xs="12" sm="12" md="12" lg="12" xl="10">
        <v-card flat>
          <v-card-text>
            <v-form v-model="isFormValid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <h1 style="text-align: right"><strong>|</strong></h1>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <h1 style="text-align: left">{{ getTitle }}</h1>
                    <!-- <h4 style="text-align: left">
                      Use this tool to create a new DOI associated to the chosen
                      dataset.
                    </h4> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>DOI</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The globally unique string that identifies the resource
                      and can't be changed.
                    </p>
                    <v-text-field disabled outlined dense :rules="rules.doi" v-model="getDOIState.doi"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>URL</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The location of the landing page with more information
                      about the resource.
                    </p>
                    <v-text-field disabled outlined dense :rules="rules.url" v-model="getDOIState.url"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>Title</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      A name or title by which the resource is known.
                    </p>
                    <v-text-field outlined :rules="rules.title" dense
                      v-model="getDOIState.titles[0].title"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>Abstract</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      Additional information about the resource.
                    </p>
                    <v-textarea outlined dense placeholder="Write here a description..."
                      v-model="getDOIState.descriptions[0].description"></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>Creators</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The main researchers or organizations involved in
                      producing the resource, in priority order.
                    </p>
                    <v-radio-group v-model="getDOIState.creators[0].nameType" @change="resetNames(i)" row>
                      <v-radio label="Organization" value="Organizational"></v-radio>
                      <v-radio label="Person" value="Personal"></v-radio>
                    </v-radio-group>
                    <v-text-field outlined dense label="Name *" :rules="rules.creator.name" :disabled="getDOIState.creators[0].nameType === 'Personal'
                      " v-model="getDOIState.creators[0].name"></v-text-field>
                    <v-text-field outlined dense label="Family Name" @input="updateFullName(-1)"
                      v-if="getDOIState.creators[0].nameType === 'Personal'"
                      v-model="getDOIState.creators[0].familyName"></v-text-field>
                    <v-text-field outlined dense label="Given Name" @input="updateFullName(-1)"
                      v-if="getDOIState.creators[0].nameType === 'Personal'"
                      v-model="getDOIState.creators[0].givenName"></v-text-field>

                    <v-combobox dense outlined
                      hint="Affiliation names and identifiers are provided by the Research Organization Registry (ROR)."
                      persistent-hint label="Affiliation" @update:search-input="changeQuery" :items="queryresult"
                      item-text="name" v-model="getDOIState.creators[0].affiliation[0]"></v-combobox>

                    <div v-for="(creator, i) in getDOIState.creators.slice(1)" :key="i">
                      <v-divider></v-divider>
                      <v-radio-group v-model="creator.nameType" @change="resetNames(i)" row>
                        <v-radio label="Organization" value="Organizational"></v-radio>
                        <v-radio label="Person" value="Personal"></v-radio>
                      </v-radio-group>
                      <v-text-field outlined dense required :rules="rules.creator.name" label="Name *"
                        :disabled="creator.nameType === 'Personal'" v-model="creator.name"></v-text-field>
                      <v-text-field outlined dense @input="updateFullName(i)" label="Family Name"
                        v-if="creator.nameType === 'Personal'" v-model="creator.familyName"></v-text-field>
                      <v-text-field outlined dense label="Given Name" @input="updateFullName(i)"
                        v-if="creator.nameType === 'Personal'" v-model="creator.givenName"></v-text-field>

                      <v-combobox dense outlined
                        hint="Affiliation names and identifiers are provided by the Research Organization Registry (ROR)."
                        persistent-hint label="Affiliation" @update:search-input="changeQuery" :items="queryresult"
                        item-text="name" v-model="creator.affiliation[0]"></v-combobox>
                      <v-btn rounded color="black" class="my-1" dark @click="removeCreator(i)"><v-icon
                          left>mdi-delete</v-icon>Remove Creator</v-btn>
                    </div>
                    <v-btn rounded class="my-3" @click="addCreator()"><v-icon left>mdi-plus-circle</v-icon>Add
                      Creator</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>Rights</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      Rights information for the resource.
                    </p>
                    <v-combobox disabled outlined dense :items="licenses" item-text="rights" persistent-hint
                      hint="The list of standard licenses is provided by SPDX."
                      v-model="getDOIState.rightsList[0]"></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right"><strong>Publisher</strong></p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The name of the entity that holds, archives, publishes
                      prints, distributes, releases, issues, or produces thename
                      resource.
                    </p>
                    <v-text-field disabled outlined dense v-model="getDOIState.publisher"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right">
                      <strong>Publication Year</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The year when the resource was or will be made publicly
                      available.
                    </p>
                    <v-text-field disabled outlined dense :rules="rules.publicationYear"
                      v-model="getDOIState.publicationYear"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3">
                    <p style="text-align: right">
                      <strong>Resource Type General</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <p style="text-align: left">
                      The general type of the resource.
                    </p>
                    <v-select disabled outlined dense :rules="rules.resourceType"
                      v-model="getDOIState.types.resourceTypeGeneral" :items="[
                          'Audiovisual',
                          'Book',
                          'Book Chapter',
                          'Collection',
                          'Computational Notebook',
                          'Conference Paper',
                          'Conference Proceeding',
                          'Data Paper',
                          'Dataset',
                          'Dissertation',
                          'Event',
                          'Image',
                          'Interactive Resource',
                          'Journal',
                          'Journal Article',
                          'Model',
                          'Output management plan',
                          'Peer Review',
                          'Physical Object',
                          'Preprint',
                          'Report',
                          'Service',
                          'Software',
                          'Sound',
                          'Standard',
                          'Text',
                          'Workflow',
                          'Other',
                        ]"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" lg="3"> </v-col>
                  <v-col cols="12" sm="8" lg="9">
                    <v-btn @click="validateForm()">Continue</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <ConfirmFormDialog :dialog.sync="confirmDialog" :doiState="getDOIState" :dataset="dataset"></ConfirmFormDialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ConfirmFormDialog from "./ConfirmFormDialog.vue";
import { mapActions } from "vuex";
import urljoin from "url-join";
const DATASET_ROOT_FOSSILE = process.env.VUE_APP_DATASET_FOSSILE;
const DATASET_ROOT_MODERNE = process.env.VUE_APP_DATASET_MODERNE;
const DATACITE_ROOT = process.env.VUE_APP_DATACITE_ROOT;
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
export default {
  components: { ConfirmFormDialog },
  data() {
    return {
      dataset: null,
      licenses: [],
      isFormValid: false,
      querysearch: "",
      queryresult: [],
      selectedValue: null,
      confirmDialog: false,
      rules: {
        creator: {
          name: [(v) => !!v || "This field is required."],
        },
        doi: [(v) => !!v || "This field is required."],
        url: [(v) => !!v || "This field is required."],
        title: [(v) => !!v || "This field is required."],
        publicationYear: [(v) => !!v || "This field is required."],
        resourceType: [(v) => !!v || "This field is required."],
      },
      doiState: {
        id: "",
        doi: "",
        event: "publish",
        url: urljoin(
          this.$route.params.fossilormodern === "fossil"
            ? DATASET_ROOT_FOSSILE.replace('#', '/#')
            : DATASET_ROOT_MODERNE.replace('#', '/#')
          ,
          this.$route.params.uuid),
        types: {
          ris: "DATA",
          bibtex: "misc",
          citeproc: "dataset",
          schemaOrg: "Dataset",
          resourceTypeGeneral: "Dataset",
        },
        creators: [
          {
            name: "",
            nameType: "Organizational",
            givenName: "",
            familyName: "",
            affiliation: [],
            nameIdentifiers: [],
          },
        ],
        titles: [
          {
            lang: "en",
            title: "",
            titleType: null,
          },
        ],
        publisher: "ESPRI/IPSL",
        subjects: [],
        contributors: [],
        dates: [],
        publicationYear: new Date().getFullYear(),
        identifiers: [],
        sizes: [],
        formats: [],
        descriptions: [
          {
            lang: null,
            description: "",
            descriptionType: "Abstract",
          },
        ],
        rightsList: [
          {
            rights: "Creative Commons Attribution 3.0 Unported",
            rightsIdentifier: "CC-BY-3.0",
            rightsIdentifierScheme: "SPDX",
            rightsUri: "https://creativecommons.org/licenses/by/3.0/legalcode",
            schemeUri: "https://spdx.org/licenses/",
          },
        ],
        geoLocations: [],
        fundingReferences: [],
        relatedIdentifiers: [],
        schemaVersion: "http://datacite.org/schema/kernel-4",
      },
    };
  },
  computed: {
    getTitle() {
      if (this.$route.params.editorcreate === "create") {
        return "Create a new DOI.";
      } else if (this.$route.params.editorcreate === "edit") {
        return "Edit an existing DOI.";
      }
      return "";
    },
    getDOIState() {
      return this.doiState;
    },
  },
  methods: {
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    async changeQuery(e) {
      if (e != "") {
        console.log(e);
        let response = await axios.get(
          "https://api.ror.org/organizations?query=" + e
        );
        this.queryresult = response.data.items.map((x) => {
          return {
            name: x.name,
            schemeUri: "https://ror.org",
            affiliationIdentifier: x.id,
            affiliationIdentifierScheme: "ROR",
          };
        });
      }
    },
    async fetchDatasetInfo() {
      const response = await axios.get(
        urljoin(
          HOST_ROOT,
          this.$route.params.fossilormodern === "fossil"
            ? "api/fossil-dataset/?uuid=" + this.$route.params.uuid
            : "api/modern-dataset/?uuid=" + this.$route.params.uuid
        )
      );
      this.doiState.titles[0].title = "African Pollen Database: " + response.data[0].dataset_title + " fossil pollen dataset";
      let authors = response.data[0].json_metadata.investigators;
      if (authors) {
        this.doiState.creators = authors.split(';').map(author => {
          return {
            "name": author,
            "nameType": "Personal",
            "givenName": author.split(',')[1].trim(),
            "familyName": author.split(',')[0].trim(),
            "affiliation": [

            ],
            "nameIdentifiers": [

            ]
          }
        })
      } else {
        this.doiState.creators = [{
          "name": "",
          "nameType": "Personal",
          "givenName": "",
          "familyName": "",
          "affiliation": [

          ],
          "nameIdentifiers": [

          ]
        }]
      }

      this.dataset = response.data[0];
      this.setIsLoading(false);
      this.setLoadingMessage("");
    },
    async fetchLicenses() {
      const response = await axios.get("json/licenses.json", {
        baseURL: window.location.origin,
      });
      this.licenses = response.data.licenses
        .map((x) => {
          return {
            rights: x.name,
            rightsUri: x.seeAlso[0],
            schemeUri: "https://spdx.org/licenses/",
            rightsIdentifier: x.licenseId,
            rightsIdentifierScheme: "SPDX",
          };
        })
        .sort((a, b) => (a.rights > b.rights ? 1 : -1));
    },
    async getRandomDOI() {
      const response = await axios.get(
        "https://api.test.datacite.org/dois/random?prefix=10.14768"
      );
      let doisplit = response.data.dois[0].split("/");
      this.doiState.doi = [doisplit[0], "APD." + doisplit[1]].join("/");
    },
    removeCreator(index) {
      this.doiState.creators.splice(index + 1, 1);
    },
    updateFullName(i) {
      this.doiState.creators[i + 1].name =
        this.doiState.creators[i + 1].familyName +
        ", " +
        this.doiState.creators[i + 1].givenName;
    },
    resetNames(i) {
      this.doiState.creators[i + 1].familyName = "";
      this.doiState.creators[i + 1].givenName = "";
    },
    addCreator() {
      this.doiState.creators.push({
        name: "",
        nameType: "Organizational",
        givenName: "",
        familyName: "",
        affiliation: [],
        nameIdentifiers: [],
      });
    },
    validateForm() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.confirmDialog = true;
      } else {
        window.scrollTo(0, 0);
      }
    },
    async fetchDOIinfo() {
      try {
        this.setLoadingMessage("Loading DOI information. Please wait...");
        this.setIsLoading(true);
        const response = await axios.get(
          urljoin(
            HOST_ROOT,
            this.$route.params.fossilormodern === "fossil"
              ? "api/fossil-dataset/?uuid=" + this.$route.params.uuid
              : "api/modern-dataset/?uuid=" + this.$route.params.uuid
          )
        );

        this.doiState.doi = response.data[0].doi.doi;
        let response2 = await axios.get(
          urljoin(DATACITE_ROOT, this.doiState.doi),
          {}
        );
        let doiInfo = response2.data.data.attributes;
        if (doiInfo.descriptions.length === 0) {
          doiInfo.descriptions.push({
            lang: null,
            description: "",
            descriptionType: "Abstract",
          });
        }
        this.doiState = doiInfo;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      } catch (error) {
        this.setIsLoading(false);
        this.setLoadingMessage("");
        alert(error);

        return;
      }
    },

    async onMounted() {
      this.fetchLicenses();
      if (this.$route.params.editorcreate === "create") {
        this.setLoadingMessage("Loading DOI information. Please wait...");
        this.setIsLoading(true);

        await this.getRandomDOI();
        await this.fetchDatasetInfo();
      } else if (this.$route.params.editorcreate === "edit") {
        this.fetchDOIinfo();
      }
    },
  },
  mounted() {
    this.onMounted();
  },
};
</script>

<style></style>