import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_PALEODATAIPSL_API
    // other configuration options
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Modify the request config here (e.g. add headers, authentication tokens, etc.)
        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g. parse, transform, etc.)
        return response;
    },
    (error) => {
        // Handle response error here
        return Promise.reject(error);
    }
);

export default axiosInstance;

