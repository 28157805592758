import { render, staticRenderFns } from "./DatasetTaxonomyList.vue?vue&type=template&id=137bb6d8"
import script from "./DatasetTaxonomyList.vue?vue&type=script&lang=js"
export * from "./DatasetTaxonomyList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports