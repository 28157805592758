<template>
  <v-dialog
    v-model="dialog"
    v-if="dialog"
    max-width="800px"
    scrollable
    @click:outside="closeDialog"
  >
    <v-card height="600px">
      <v-card-title
        ><v-icon left>
          {{ getTitleIcon }}
        </v-icon>
        Confirm DOI {{ getTitle }}
      </v-card-title>
      <v-card-text>
        <v-banner
          v-if="this.$route.params.editorcreate === 'create'"
          color="warning"
          dark
          elevation="2"
          single-line
          ><v-icon slot="icon" dark size="30">
            mdi-alert-octagon-outline
          </v-icon>
          Check DOI information before creaing the DOI. The creation of a DOI
          cannot be undone.</v-banner
        >
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>DOI</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.doi }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>URL</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.url }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Title</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.titles[0].title }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Abstract</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.descriptions[0].description }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Creator(s)</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <ul>
                <li v-for="creator in doiState.creators" :key="creator.name">
                  <p>
                    {{
                      creator.affiliation.length > 0
                        ? creator.name +
                          " (" +
                          creator.affiliation[0].name +
                          ")"
                        : creator.name
                    }}
                  </p>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Rights</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>
                {{
                  doiState.rightsList.length > 0
                    ? doiState.rightsList[0].rights
                    : ""
                }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Publisher</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.publisher }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Publication Year</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.publicationYear }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <p style="text-align: right"><strong>Resource Type</strong></p>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <p>{{ doiState.types.resourceTypeGeneral }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" dark @click="closeDialog">Cancel</v-btn>
        <v-btn @click="confirmAction()"
          ><v-icon left>{{ getConfirmButtonIcon }}</v-icon
          >{{ getConfirmButtonText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
const DATACITE_ROOT = process.env.VUE_APP_DATACITE_ROOT;
const DATACITE_USERNAME = process.env.VUE_APP_DATACITE_USERNAME;
const DATACITE_PASSWORD = process.env.VUE_APP_DATACITE_PASSWORD;
import axios from "axios";
import { mapGetters } from "vuex";
import urljoin from "url-join";
export default {
  props: ["dialog", "doiState", "dataset"],
  methods: {
    closeDialog() {
      this.$emit("update:dialog", !this.dialog);
    },
    async createDOI() {
      /* Function to create a new DOI associated to the selected dataset.
      
      At first it creates the DOI via the DataCite REST API, then, if there is no error,
      it registers the DOI in the database, together wiht the UUID of the dataset.*/

      let jsondata = {
        data: {
          id: this.doiState.doi,
          type: "dois",
          attributes: this.doiState,
        },
      };
      try {
        /* HTTP POST on DataCite REST API */
        await axios.post(DATACITE_ROOT, jsondata, {
          headers: {
            "Content-Type": "application/vnd.api+json",
            Accept: "application/vnd.api+json",
          },
          auth: {
            username: DATACITE_USERNAME,
            password: DATACITE_PASSWORD,
          },
        });

        /* Creating a record in the DB (UUID,DOI) */
        const headers = {
          Authorization: "Token " + this.getToken,
        };

        await axios.post(
          urljoin(HOST_ROOT, 
          this.$route.params.fossilormodern === "fossil"
                  ?  "api/fossil-doi/"
                  :  "api/modern-doi/"
          ),
          {
            uuid: this.$route.params.uuid,
            doi: jsondata.data.id,
            is_internal: true,
          },
          {
            headers: headers,
          }
        );

        this.closeDialog();
        this.$router.go(-1);
      } catch (error) {
        alert(error);
        this.closeDialog();
        return;
      }
    },
    async updateDOI() {
      /* Function to update the metadata of the DOI associated to the selected dataset.
       */

      let jsondata = {
        data: {
          attributes: this.doiState,
        },
      };
      try {
        /* HTTP POST on DataCite REST API */
        await axios.put(urljoin(DATACITE_ROOT, this.doiState.doi), jsondata, {
          headers: {
            "Content-Type": "application/vnd.api+json",
            Accept: "application/vnd.api+json",
          },
          auth: {
            username: DATACITE_USERNAME,
            password: DATACITE_PASSWORD,
          },
        });

        this.closeDialog();
        this.$router.go(-1);
      } catch (error) {
        alert(error);
        this.closeDialog();
        return;
      }
    },
    confirmAction() {
      if (this.$route.params.editorcreate === "create") {
        this.createDOI();
      } else if (this.$route.params.editorcreate === "edit") {
        this.updateDOI();
      }
      return;
    },
  },
  computed: {
    ...mapGetters("auth", ["getToken"]),
    getTitle() {
      if (this.$route.params.editorcreate === "create") {
        return "creation";
      } else if (this.$route.params.editorcreate === "edit") {
        return "modification";
      }
      return "";
    },
    getTitleIcon() {
      if (this.$route.params.editorcreate === "create") {
        return "mdi-card-plus";
      } else if (this.$route.params.editorcreate === "edit") {
        return "mdi-pencil";
      }
      return "";
    },
    getConfirmButtonIcon() {
      if (this.$route.params.editorcreate === "create") {
        return "mdi-card-plus";
      } else if (this.$route.params.editorcreate === "edit") {
        return "mdi-pencil";
      }
      return "";
    },
    getConfirmButtonText() {
      if (this.$route.params.editorcreate === "create") {
        return "Confirm DOI creation";
      } else if (this.$route.params.editorcreate === "edit") {
        return "Confirm DOI edit";
      }
      return "";
    },
  },
};
</script>

<style>
</style>