import Api from "./axios"

/**
 * Service for handling dataset-related API calls.
 * @namespace datasetService
 */
const datasetService = {
    /**
     * Retrieves all datasets.
     * @memberof datasetService
     * @returns {Promise} A promise that resolves to the response data.
     */
    getDatasets() {
        return Api.get("/datasets/")
    },

    /**
     * Retrieves a specific dataset by its ID.
     * @memberof datasetService
     * @param {string} dataset_id - The ID of the dataset.
     * @returns {Promise} A promise that resolves to the response data.
     */
    getDataset(dataset_id) {
        return Api.get(`/datasets/${dataset_id}/`)
    },

    /**
     * Retrieves datasets' general information based on optional filters.
     * @memberof datasetService
     * @param {Object} options - The optional filters.
     * @returns {Promise} A promise that resolves to the response data.
     */
    getDatasetsGeneralInfo(options) {

        let project_ids = options.project_ids || [];
        let archive_ids = options.archive_ids || [];
        let region_ids = options.region_ids || [];
        let site_ids = options.site_ids || [];
        let material_ids = options.material_ids || [];
        let min_lat = options.min_lat || null;
        let max_lat = options.max_lat || null;
        let min_lon = options.min_lon || null;
        let max_lon = options.max_lon || null;
        let min_alt = options.min_alt || null;
        let max_alt = options.max_alt || null;
        let with_chronology = options.with_chronology || false;
        let with_data = options.with_data || false;
    
        return Api.get("/datasets-generalinfo/", {
            params: {
                project_ids: JSON.stringify(project_ids),
                archive_ids: JSON.stringify(archive_ids),
                region_ids: JSON.stringify(region_ids),
                site_ids: JSON.stringify(site_ids),
                material_ids: JSON.stringify(material_ids),
                min_lat: min_lat,
                max_lat: max_lat,
                min_lon: min_lon,
                max_lon: max_lon,
                min_alt: min_alt,
                max_alt: max_alt,
                with_chronology: with_chronology,
                with_data: with_data
            }
        })
    },

    downloadDatasetMetadata(dataset_id) {
        return Api.get(`/datasets/${dataset_id}/jsonld/`)
    },
    
    /* 
        Function to get the data columns for each data table in a dataset.
        Used in the DowncorePlots visualisation tool.
    */
    getDataColumns(dataset_id) {
        return Api.get(`/datasets/${dataset_id}/data_columns/`)
    },
}

export default datasetService;
