<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="text-right">Dataset ID</h3>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            v-model="uuid"
            disabled
            placeholder="Dataset ID"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="text-right">Dataset Title</h3>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            v-model="dataset_title"
            disabled
            placeholder="Dataset ID"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="doi">
        <v-col cols="12" md="4">
          <h3 class="text-right">DOI</h3>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            v-model="doi.doi"
            disabled
            placeholder="Dataset DOI"
          >
            <template slot="append-outer">
              <v-btn
                class="lock-button"
                color="black"
                :disabled="!doi"
                icon
                :href="getDoiUrl"
                target="_blank"
                ><v-icon>mdi-page-next</v-icon></v-btn
              >
            </template>
          </v-text-field>

          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row v-if="!doi">
        <v-col cols="12" md="12">
          <p>This dataset has not a DOI already.</p>
        </v-col>
      </v-row>
      <v-row v-if="doi">
        <v-col cols="12" md="12">
          <p>
            Created on {{ new Date(doi.created_at).toLocaleDateString() }} at
            {{ new Date(doi.created_at).toLocaleTimeString() }} via
            {{
              doi.is_internal
                ? "DataCite. The DOI cannot be deleted or replaced."
                : "external source."
            }}
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="12"
          ><h1 class="text-center mt-6 mb-10">
            What do you want to do?
          </h1></v-col
        >
      </v-row>
      <v-row class="">
        <v-col v-if="doi" cols="12" md="12">
          <v-btn
            v-if="doi.is_internal"
            :disabled="!doi"
            :to="{
              name: 'DOIEditForm',
              params: { editorcreate: 'edit' },
            }"
            ><v-icon left>mdi-pencil</v-icon>Edit DOI Information</v-btn
          >
        </v-col>
        <v-col v-if="doi && !doi.is_internal" cols="12" md="12">
          <v-btn v-if="!doi.is_internal" :disabled="!doi" color="error"
            ><v-icon left>mdi-delete</v-icon>Delete doi</v-btn
          >
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            :disabled="doi"
            color="success"
            :to="{
              name: 'DOIEditForm',
              params: { editorcreate: 'create' },
            }"
            ><v-icon left>mdi-plus</v-icon>Create new DOI</v-btn
          >
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            :disabled="doi"
            color="warning"
            :to="{
              name: 'DOIAssociateExisting',
            }"
            ><v-icon left>mdi-link-variant-plus</v-icon>Associate existing
            DOI</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import urljoin from "url-join";
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
export default {
  data() {
    return {
      doi: null,
      dataset_title: null,
      uuid: this.$route.params.uuid,
    };
  },
  methods: {
    async fetchDatasetInfo() {
      const response = await axios.get(
        urljoin(
          HOST_ROOT,
          this.$route.params.fossilormodern === "fossil"
              ? "api/fossil-dataset/?uuid=" + this.$route.params.uuid
              : "api/modern-dataset/?uuid=" + this.$route.params.uuid
          
        )
      );
      this.dataset_title = response.data[0].dataset_title;
      this.doi = response.data[0].doi;
    },
  },
  computed: {
    getDoiUrl() {
      if (this.doi) {
        if (this.doi.is_internal) {
          return "https://handle.stage.datacite.org/" + this.doi.doi.toString();
        } else {
          return "https://doi.org/" + this.doi.doi.toString();
        }
      }
      return "";
    },
  },
  mounted() {
    this.fetchDatasetInfo();
  },
};
</script>

<style>
.lock-button {
  pointer-events: auto;
}
</style>