import Vuex from "vuex";
import Vue from "vue";
import photosstore from "./modules/photosstore.js";
import taxons from "./modules/taxons.js";
import pollenmoderne from "./modules/pollenmoderne.js";
import pollenfossile from "./modules/pollenfossile.js";
import lipdpollenmoderne from "./modules/lipdpollenmoderne.js";
import admin_fossilpollen from "./modules/admin_fossilpollen.js";
import admin_pollenmoderne from "./modules/admin_pollenmoderne.js";
import jsonconfig from "./modules/jsonconfig.js";
import db_fossilpercentages from "./modules/db_fossilpercentages.js";
import auth from "./modules/auth.js";
import fossilpollenpercentage from "./modules/v2/visualisation-tools/fossil-pollen-percentage.js";

// Load Vuex
Vue.use(Vuex);
Vue.config.devtools = true;

//Create store
const store = new Vuex.Store({
  modules: {
    photosstore,
    taxons,
    pollenmoderne,
    pollenfossile,
    lipdpollenmoderne,
    admin_fossilpollen,
    admin_pollenmoderne,
    auth,
    jsonconfig,
    db_fossilpercentages,
    fossilpollenpercentage,
  },
});

export default store;
