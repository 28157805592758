<template>
  <div>
    <v-data-table dense :headers="headd" :items="taxonss">
      <template v-slot:item.id_taxon="{ item }">
        <v-icon v-if="item.id_taxon" color="success"> mdi-check-circle </v-icon>
        <v-icon v-if="!item.id_taxon" color="error"> mdi-close-circle </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import urljoin from "url-join";
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
export default {
  props: ["dataset"],
  data() {
    return {
      measurement: { columns: [] },
      taxonss: [],
      headd: [
        {
          text: "Variable Name",
          align: "start",
          value: "authortaxon_name",
        },
        {
          text: "APD taxonomy",
          value: "taxon_name",
        },
        {
          text: "APD family name",
          value: "family_name",
        },
        {
          text: "Plant Habit",
          value: "plant_habit",
        },
        {
          text: "Pollen Habit",
          value: "pollen_habit_code",
        },
        {
          text: "Is APD",
          value: "id_taxon",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchAuthorTaxonsDeep"]),
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    async checkTaxonomyDictionnary() {
      this.setLoadingMessage(
        "Checking taxonomies on dictionary. Please wait..."
      );
      this.setIsLoading(true);

      /* Downloading related paleodata files */
      var url_paleodata = urljoin(
        HOST_ROOT,
        this.$route.params.modernfossil === "fossil"
          ? `api/fossil-paleodata?id_dataset=${this.dataset.id_dataset}&filetype=raw+count`
          : `api/modern-paleodata?id_dataset=${this.dataset.id_dataset}&filetype=raw+count`
      );
      const response3 = await axios.get(url_paleodata);
      let rawCountFile = response3.data[0];
      if (!rawCountFile){
          this.setIsLoading(false);
          this.setLoadingMessage("");
          return
      }
      let url = urljoin(
        HOST_ROOT,
        this.$route.params.modernfossil === "fossil"
          ? `api/fossil-paleodata-complete/${rawCountFile.id_paleodata}/`
          : `api/modern-paleodata-complete/${rawCountFile.id_paleodata}/`
      );

      const response1 = await axios.get(url);

      this.measurement.columns=response1.data.columns

      let taxonListComplete = await this.fetchAuthorTaxonsDeep(
        this.measurement.columns.map((c) => c.variableName)
      );
      let dict = {};
      for (let taxon of taxonListComplete.data) {
        dict[taxon.authortaxon_name.toString().trim()] = taxon;
      }

      this.taxonss = this.measurement.columns.map((c) => {
        if (Object.keys(dict).includes(c.variableName.toString().trim())) {
          return {
            id_authortaxon:
              dict[c.variableName.toString().trim()].id_authortaxon,
            authortaxon_name:
              dict[c.variableName.toString().trim()].authortaxon_name,
            id_taxon: dict[c.variableName.toString().trim()].id_taxon.id_taxon,
            taxon_name:
              dict[c.variableName.toString().trim()].id_taxon.taxon_name,
            plant_habit:
              dict[c.variableName.toString().trim()].id_taxon.plant_habit,
            id_pollen_habit:
              dict[c.variableName.toString().trim()].id_taxon.id_pollen_habit
                .id_pollen_habit,
            pollen_habit_code:
              dict[c.variableName.toString().trim()].id_taxon.id_pollen_habit
                .pollen_habit_code,
            id_family:
              dict[c.variableName.toString().trim()].id_taxon.id_family
                .id_family,
            family_name:
              dict[c.variableName.toString().trim()].id_taxon.id_family
                .family_name,
          };
        } else {
          return {
            id_authortaxon: null,
            authortaxon_name: c.variableName.toString().trim(),
            id_taxon: null,
            taxon_name: null,
            plant_habit: null,
            id_pollen_habit: null,
            pollen_habit_code: null,
            id_family: null,
            family_name: null,
          };
        }
      });
      this.setIsLoading(false);
      this.setLoadingMessage("");
    },
  },
  mounted() {
    this.checkTaxonomyDictionnary();
  },
};
</script>

<style>
</style>