<template>
  <div>
    <v-container>
      <v-row
        ><v-col justify="center" md="12" lg="12">
          <v-card color="rgb(255, 0, 0, 0)">
            <v-card-title class="text-h5" style="word-break: keep-all">
              <v-avatar dark class="white--text">
                <v-icon color="#2B2118">mdi-database-clock</v-icon>
              </v-avatar>
              {{
                $route.params.modernfossil === "fossil"
                  ? "Fossil Pollen Data"
                  : "Modern Pollen Data"
              }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col justify="center" md="12" lg="12"
          ><v-btn :disabled="$route.query.goindex" @click="$router.go(-1)"
            ><v-icon left>mdi-arrow-left</v-icon>Back</v-btn
          ></v-col
        >
      </v-row> -->
      <!-- GENERAL INFORMATION -->
      <v-row>
        <v-col cols="12" sm="4" lg="2">
          <p class="text-sm-right"><strong>General Information</strong></p>
        </v-col>
        <v-col cols="12" sm="8" lg="10">
          <div :class="'pt-2'" class="black">
            <div class="white text-center">
              <v-card flat>
                <v-card-title>
                  <h3>{{ dataset ? dataset.dataset_title : "" }}</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="8">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr
                              :style="'background: ' + choseColor(ii + 1)"
                              class="text-left"
                              v-for="(item, ii) in getGeneralInfo"
                              :key="item.label"
                            >
                              <td>
                                <strong>{{ item.label }}</strong>
                              </td>
                              <td>{{ item.value }}</td>
                            </tr>
                            <tr class="text-left">
                              <td>
                                <strong>Download Dataset</strong>
                              </td>
                              <td>
                                <v-btn
                                  :href="getDownloadURL(dataset.uuid)"
                                  download
                                  small
                                  ><v-icon left>mdi-folder-zip</v-icon
                                  >.lpd</v-btn
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <div id="container">
                        <div id="mapContainer"></div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- CITATION -->
      <v-row :v-if="dataset ? this.dataset.doi : false">
        <v-col cols="12" sm="4" lg="2">
          <p class="text-sm-right"><strong>Citation</strong></p>
        </v-col>
        <v-col cols="12" sm="8" lg="10">
          <div :class="'pt-2'" class="black">
            <div class="white text-center">
              <v-card flat>
                <v-card-title>
                  <h3>Citation Information</h3>
                </v-card-title>
                <v-card-text>
                  <p v-if="dataset && !dataset.doi">
                    Citation information is still not available for this
                    dataset.
                  </p>

                  <v-simple-table v-if="dataset && dataset.doi" dense>
                    <template v-slot:default>
                      <tbody>
                        <tr class="text-left">
                          <td>
                            <strong>DOI</strong>
                          </td>
                          <td>
                            <a :href="doiInfo['URL']" target="_blank">{{ dataset.doi.doi }} </a>
                          </td>
                        </tr>
                        <tr
                          :style="'background: ' + choseColor(ii + 1)"
                          class="text-left"
                          v-for="(item, ii) in getCitationInfo"
                          :key="item.label"
                        >
                          <td>
                            <strong>{{ item.label }}</strong>
                          </td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- PUBLICATIONS -->
      <v-row>
        <v-col cols="12" sm="4" lg="2">
          <p class="text-sm-right"><strong>Related Publications</strong></p>
        </v-col>
        <v-col cols="12" sm="8" lg="10">
          <div :class="'pt-2'" class="black">
            <div class="white text-center">
              <v-card flat>
                <v-card-title>
                  <h3>Publications</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Author(s)</th>
                              <th class="text-left">Year</th>
                              <th class="text-left">Title</th>
                              <th class="text-left">Issue</th>
                              <th class="text-left">Vol.</th>
                              <th class="text-left">Ed.</th>
                              <th class="text-left">Journal</th>
                              <th class="text-left">Pages</th>
                              <th class="text-left">DOI</th>
                              <th class="text-left">Abstract</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              :style="'background: ' + choseColor(ii + 1)"
                              class="text-left"
                              v-for="(item, ii) in getPub"
                              :key="ii"
                            >
                              <td>
                                {{ item.author.map((a) => a.name).join("; ") }}
                              </td>
                              <td>{{ item.year }}</td>
                              <td>{{ item.title }}</td>
                              <td>{{ item.issue }}</td>
                              <td>{{ item.volume }}</td>
                              <td>{{ item.edition }}</td>
                              <td>{{ item.journal }}</td>
                              <td>{{ item.pages }}</td>
                              <td>
                                <a
                                  v-if="
                                    item.identifier &&
                                    item.identifier
                                  "
                                  :href="item.identifier.url ? item.identifier.url: 'https://doi.org/' + item.identifier.id"
                                  target="_blank"
                                  >{{ item.identifier.id }}
                                </a>
                              </td>
                              <td>
                                <v-btn icon small @click="seeAbstract(item)"
                                  ><v-icon>mdi-text-box-search</v-icon></v-btn
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
        <!-- Abstract Dialog -->
        <v-dialog
          v-model="dialogAbstract"
          scrollable
          width="700px"
          hide-overlay
        >
          <v-card height="500px">
            <v-card-title>Abstract</v-card-title>
            <v-card-text
              ><p v-if="abstractItem">
                {{ abstractItem.abstract }}
              </p></v-card-text
            >
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Data Files -->
      <v-row v-if="dataset">
        <v-col cols="12" sm="4" lg="2">
          <p class="text-sm-right"><strong>Data</strong></p>
        </v-col>
        <v-col cols="12" sm="8" lg="10">
          <div :class="'pt-2'" class="black">
            <div class="white text-center">
              <v-card flat>
                <v-card-title>
                  <h3>Associated Files</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <DatasetFilesList
                        :dataset.sync="dataset"
                      ></DatasetFilesList>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Data Files -->
      <v-row v-if="dataset">
        <v-col cols="12" sm="4" lg="2">
          <p class="text-sm-right"><strong>Taxonomy</strong></p>
        </v-col>
        <v-col cols="12" sm="8" lg="10">
          <div :class="'pt-2'" class="black">
            <div class="white text-center">
              <v-card flat>
                <v-card-title>
                  <h3>Dataset Taxonomy List</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <DatasetTaxonomyList :dataset.sync="dataset"></DatasetTaxonomyList>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import urljoin from "url-join";
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
import { mapActions } from "vuex";
const DOI_API_INTERNAL = process.env.VUE_APP_DOI_API_INTERNAL;
const DOI_API_EXTERNAL = process.env.VUE_APP_DOI_API_EXTERNAL;
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import DatasetFilesList from "./DatasetFilesList.vue";
import DatasetTaxonomyList from './DatasetTaxonomyList.vue';

export default {
  components: { DatasetFilesList, DatasetTaxonomyList },
  data() {
    return {
      dataset: null, // the dataset object
      mapDiv: null,
      doiInfo: {},
      dialogAbstract: false, // open dialog to read publication abstract
      abstractItem: null, // the object containing the abstract
      filesList: [],
    };
  },

  methods: {
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    choseColor(ii) {
      return ii % 2 !== 0 ? `#EEEEEE` : "white";
    },
    async fetchDatasetInfo() {
      const response = await axios.get(
        urljoin(
          HOST_ROOT,
          this.$route.params.modernfossil === "fossil"
            ? "api/fossil-dataset/"
            : "api/modern-dataset/",
          "?uuid=" + this.$route.params.uuid
        )
      );
      this.dataset = response.data[0];

      if (this.dataset) {
        if (this.dataset.doi) {
          this.fetchDOIinfo(this.dataset.doi.is_internal);
        }
      }
    },
    async fetchDOIinfo(is_internal) {
      try {
        this.setLoadingMessage("Loading DOI information. Please wait...");
        this.setIsLoading(true);

        let response2 = await axios.get(
          urljoin(
            is_internal ? DOI_API_INTERNAL : DOI_API_EXTERNAL,
            this.dataset.doi.doi
          ),
          {
            headers: { Accept: "application/vnd.citationstyles.csl+json" },
          }
        );
        let doiInfo = response2.data;
        this.doiInfo = doiInfo;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      } catch (error) {
        this.setIsLoading(false);
        this.setLoadingMessage("");
        alert(error);

        return;
      }
    },
    getDownloadURL(uuid) {
      let downloadURL = urljoin(
        HOST_ROOT,
        this.$route.params.modernfossil === "fossil"
          ? "api/fossil-dataset/lipd"
          : "api/modern-dataset/lipd",
        uuid.toString()
      );
      return downloadURL;
    },
    setupLeafletMap: function () {
      const mapDiv = L.map("mapContainer").setView(
        [
          this.dataset.json_metadata.geo.geometry.coordinates[0],
          this.dataset.json_metadata.geo.geometry.coordinates[1],
        ],
        2
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          minZoom: 2,
          id: "mapbox/satellite-v9",
          accessToken:
            "pk.eyJ1IjoiYWxlbW9yaSIsImEiOiJja2U0MmdkZHowcDFyMnhwZGc0dWgxNTIwIn0.AZJzeynr-RH04CF2OEuAoQ",
        }
      ).addTo(mapDiv);
      this.mapDiv = mapDiv;
      this.mapDiv.scrollWheelZoom.disable();
      if (this.dataset) {
        L.marker([
          this.dataset.json_metadata.geo.geometry.coordinates[1],
          this.dataset.json_metadata.geo.geometry.coordinates[0],
        ]).addTo(this.mapDiv);
      }
    },
    seeAbstract(item) {
      this.abstractItem = item;
      this.dialogAbstract = true;
    },
    async onMounted() {
      await this.fetchDatasetInfo();
      this.setupLeafletMap();
      /*       this.fetchRelatedFiles(); */
    },
  },
  computed: {
    getGeneralInfo() {
      return [
        { label: "Dataset ID", value: this.$route.params.uuid },
        {
          label: "Author(s)",
          value: this.dataset ? this.dataset.json_metadata.investigators : "",
        },
        {
          label: "Site Name",
          value: this.dataset ? this.dataset.json_metadata.geo.siteName : "",
        },
        {
          label: "Archive Type",
          value: this.dataset ? this.dataset.json_metadata.archiveType : "",
        },
        {
          label: "Country/Region",
          value: this.dataset
            ? this.dataset.json_metadata.geo.properties.location
            : "",
        },
        {
          label: "Latitude",
          value: this.dataset
            ? this.dataset.json_metadata.geo.geometry.coordinates[1]
            : "",
        },
        {
          label: "Longitude",
          value: this.dataset
            ? this.dataset.json_metadata.geo.geometry.coordinates[0]
            : "",
        },
        {
          label: "Altitude",
          value: this.dataset
            ? this.dataset.json_metadata.geo.geometry.coordinates[2]
            : "",
        },
        {
          label: "Location",
          value: this.dataset
            ? this.dataset.json_metadata.geo.properties.entloc
            : "",
        },
        {
          label: "Notes",
          value: this.dataset ? this.dataset.json_metadata.notes : "",
        },
      ];
    },
    getCitationInfo() {
      return [
        {
          label: "Title",
          value: this.doiInfo ? this.doiInfo.title : "",
        },
        {
          label: "Author(s)",
          value: this.doiInfo
            ? this.doiInfo.author
                .map((x) => (x.literal ? x.literal : x.family + ", " + x.given))
                .join("; ")
            : "",
        },
        {
          label: "Abstract",
          value: this.doiInfo ? this.doiInfo.abstract : "",
        },
        {
          label: "Publication Date",
          value: this.doiInfo
            ? [...this.doiInfo.issued["date-parts"]].reverse().join("/")
            : "",
        },
        {
          label: "Publisher",
          value: this.doiInfo ? this.doiInfo.publisher : "",
        },
        {
          label: "Copyright",
          value: this.doiInfo ? this.doiInfo.copyright : "",
        },
      ];
    },
    getPub() {
      return this.dataset ? this.dataset.json_metadata.pub : [];
    },
  },
  mounted() {
    this.onMounted();
  },
  watch: {
    "$route.params.uuid": function (uuid) {
      window.location.reload();
      console.log(uuid);
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!["fossil", "modern"].includes(to.params.modernfossil)) {
      next(false);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (!["fossil", "modern"].includes(to.params.modernfossil)) {
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped>
#mapContainer {
  z-index: 0;
  height: 330px;
}
</style>