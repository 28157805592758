import dataset from "./dataset_service"
import variable from "./variable_service"
import project from "./project_service"
import region from "./region_service"
import site from "./site_service"
import taxonomy from "./taxonomy_service"
import query from "./query_service"
import archive from "./archive_service"
import doi from "./doi_service"
import data_collection from "./data_collection_service"
import measurement_table from "./measurement_table_service"
import material from "./material_service"


const services = {
    dataset,
    variable,
    project,
    region,
    site,
    taxonomy,
    query,
    archive,
    doi,
    data_collection,
    measurement_table,
    material
}

export default services;