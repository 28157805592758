<template>
  <div>
    <p v-if="filesList.length == 0">
      This dataset has not associated files yet.
    </p>
    <v-simple-table v-if="filesList.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-if="$route.params.modernfossil === 'fossil'" class="text-left">Paleodata/Chrondata</th>
            <th class="text-left">File Type</th>
            <th class="text-left">Title</th>

            <th class="text-left">Created</th>
            <th class="text-left">Last Updated</th>
            <th class="text-right">View File</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="'background: ' + choseColor(ii + 1)"
            class="text-left"
            v-for="(item, ii) in filesList"
            :key="ii"
          >
            <td v-if="$route.params.modernfossil === 'fossil'">
              <v-chip
                small
                dark
                :color="item.paleodata_title ? 'blue' : 'green'"
                >{{ item.paleodata_title ? "Paleodata" : "Chronodata" }}</v-chip
              >
            </td>
            <td>{{ item.filetype }}</td>
            <td>
              <strong>
                {{
                  item.paleodata_title
                    ? item.paleodata_title
                    : item.chronodata_title
                    ? item.chronodata_title
                    : ""
                }}
              </strong>
            </td>

            <td>
              <span>{{ new Date(item.created_at).toDateString() }}</span>
            </td>
            <td>
              <span>{{ new Date(item.updated_at).toDateString() }}</span>
            </td>

            <td class="text-right">
              <v-btn icon small @click="loadCsvData(item, ii)"
                ><v-icon small v-if="active_csv != ii">mdi-eye-outline</v-icon
                ><v-icon v-if="active_csv === ii">mdi-eye</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div id="spreadsheet">
      <v-row v-if="csv_data">
        <v-col cols="12" lg="12">
          <DatasetSpreadsheet
            :csv_data.sync="csv_data"
            :csv_header.sync="csv_header"
            :csv_title.sync="csv_title"
            :dataset.sync="dataset"
            :key="componentKey"
          >
          </DatasetSpreadsheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DatasetSpreadsheet from "./DatasetSpreadsheet.vue";
import urljoin from "url-join";
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
export default {
  components: { DatasetSpreadsheet },
  props: ["dataset"],
  data() {
    return {
      active_csv: null,
      csv_header: null,
      csv_data: null,
      componentKey: 0,
      filesList: [],
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    choseColor(ii) {
      return ii % 2 !== 0 ? `#EEEEEE` : "white";
    },
    async fetchRelatedFiles() {
      /* Downloading related chronodata files */
      var url_chronodata = urljoin(
        HOST_ROOT,
        `api/fossil-chronodata?id_dataset=${this.dataset.id_dataset}`
      );
      const response2 = await axios.get(url_chronodata);
      let chronoFiles = response2.data;

      /* Downloading related paleodata files */
      var url_paleodata = urljoin(
        HOST_ROOT,
        this.$route.params.modernfossil === "fossil"
            ? `api/fossil-paleodata?id_dataset=${this.dataset.id_dataset}`
            : `api/modern-paleodata?id_dataset=${this.dataset.id_dataset}`,
        
      );
      const response3 = await axios.get(url_paleodata);
      let paleoFiles = response3.data;

      this.filesList = paleoFiles.concat(chronoFiles);
    },
    async loadCsvData(item, ii) {
      this.active_csv = ii;
      let url = "";
      if (item.id_paleodata) {
        url = urljoin(
          HOST_ROOT,
          this.$route.params.modernfossil === "fossil"
            ? `api/fossil-paleodata-complete/${item.id_paleodata}/`
            : `api/modern-paleodata-complete/${item.id_paleodata}/`,
          
        );
        this.csv_title = item.paleodata_title;
      } else {
        url = urljoin(
          HOST_ROOT,
          `api/fossil-chronodata-complete/${item.id_chronodata}/`
        );
        this.csv_title = item.chronodata_title;
      }

      const response = await axios.get(url);

      var csvData = response.data.csv_data;
      var header = response.data.columns.map(
        (c) => c.variableName + " [" + c.units + "]"
      );
      if (
        this.checkArrays(
          csvData[0],
          response.data.columns.map((c) => c.variableName)
        )
      ) {
        this.csv_data = csvData.slice(1);
      } else {
        this.csv_data = csvData;
      }

      this.csv_header = header;
      this.forceRerender()

      document.getElementById("spreadsheet").scrollIntoView();
    },
    checkArrays(array1, array2) {
      var is_same =
        array1.length == array2.length &&
        array1.every(function (element, index) {
          return element === array2[index];
        });
      return is_same;
    },
  },
  mounted() {
    this.fetchRelatedFiles();
  },
};
</script>

<style>
</style>