<template>
  <div>
    <v-container>
      <v-row>
        <h3 class="text-left">Spreadsheet</h3>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card flat color="grey">
            <v-card-text
              ><v-col>
                <v-row><v-spacer></v-spacer><v-btn @click="exportData" class="mb-4">Download CSV<v-icon right>mdi-download</v-icon></v-btn></v-row>
                
              </v-col>
              
              <hot-table
                v-if="csv_data"
                ref="hotTableComponent"
                class="hottable"
                :settings="getHotSettings"
            
              ></hot-table>
              <span v-if="!csv_data"
                >Select a file to show spreadsheet data.</span
              >
              </v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";

export default {
  components: {
    HotTable,
  },
  props: ["csv_data", "csv_header", "csv_title","dataset"],
  data() {
    return {};
  },
  methods: {
    exportData: function() {
      let hot = this.$refs.hotTableComponent.hotInstance;
      let plugin = hot.getPlugin('exportFile');
        plugin.downloadFile('csv', {
          bom: false,
          columnDelimiter: ',',
          columnHeaders: true,
          exportHiddenColumns: false,
          exportHiddenRows: false,
          fileExtension: 'csv',
          filename: this.dataset.dataset_title+'_'+this.csv_title+'_[YYYY]-[MM]-[DD]',
          mimeType: 'text/csv',
          rowDelimiter: '\r\n',
          rowHeaders: true
        });
    }
  },
  computed: {
    getHotSettings() {
      return {
        data: this.csv_data,
        readOnly: true,
        colHeaders: this.csv_header,
        stretchH: "all",
        height: "300",
        copyPaste: true,
        width: "auto",
        licenseKey: "non-commercial-and-evaluation",
        contextMenu: {
          items: {
            copy: {
              name: "Copy selected cells",
            },
          },
        },
      };
    },
  },
};
</script>

<style>
@import "../../../node_modules/handsontable/dist/handsontable.full.css";
</style>